<template>
<!-- 头部：导航栏 -->
  <header :style="{background: (topHeight >= 800 || route.path === '/news/newdetails') ? '#1D2431' : 'rgba(29,36,49,0.5)' }">
    <div class="header_main">
      <!-- logo -->
      <img class="logo" :src="logoWhite" alt="">
      <!-- 菜单导航栏 -->
      <div class="menu_main">
        <div class="menu_item" v-for="(item, index) in menu" :key="index" @click="selectMenu(item.path)">
          <span :style="{ color: item.path === activedMenu ? '#FFFFFF' : 'rgba(255,255,255,0.7)'}">{{item.name}}</span>
          <div v-if="activedMenu === item.path" class="line"></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, watch  } from 'vue'
import router from "@/router";
import { useRoute } from 'vue-router';
  const logoWhite = require('../assets/image/logoWhite.png')
  const logoBlack = require('../assets/image/logoBlack.png')
  // 当前选中的菜单
  const activedMenu = ref('/home')
  // 判断路由，控制当前导航标签
  let route = useRoute()
  watch(() => route.path, (newValue) => {
    if (newValue === '/news/newdetails') {
      activedMenu.value = '/news/center' // 新闻详情时，菜单：新闻中心下划线高亮
    } else {
      activedMenu.value = newValue
    }
    // 滚动页面后跳转路由，回归最顶部
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
  }, { immediate: true })
  const menu = [
    {name: '首页', path: '/home'},
    {name: '智慧平台', path: '/smartPlatform'},
    {name: '产品设备', path: '/productEquipment'},
    {name: '解决方案', path: '/programme'},
    {name: '客户案例', path: '/customerCase'},
    {name: '新闻中心', path: '/news/center'},
    {name: '关于我们', path: '/aboutUs'}
  ]
  const topHeight = ref(0);//侧边栏初始高度


  onMounted(() => {
    window.addEventListener("scroll", scrollTop, true);
  })


  function scrollTop() {
    let scroll = document.documentElement.scrollTop || document.body.scrollTop;
    topHeight.value = scroll
  }

  const selectMenu = (path) => {
    if (activedMenu.value !== path) {
      activedMenu.value = path
      router.push(path)
    }
  }
</script>

<style lang="scss" scoped>
 header {
  z-index: 99;
  width: 100%;
  top: 0;
  backdrop-filter: blur(2px);
  .header_main {
    height: 80px;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 188px;
      height: 30px;
    }
    .menu_main {
      display: flex;
      margin-left: 320px;
      .menu_item {
        margin-left: 45px;
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-flow: column;
        &:nth-of-type(1) {
          margin-left: 0px;
        }
        span {
          height: 20px;
          font-size: 16px;
          font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
          font-weight: 400;
          line-height: 19px;
        }
        .line {
          width: 40px;
          height: 2px;
          margin-top: 8px;
          background: linear-gradient(270deg, #2CEDFA 0%, #4397FB 100%);
          box-shadow: 0px -4px 10px 0px rgba(44,237,250,0.7);
        }
      }
    }
  }
 }
</style>