import { createRouter, createWebHistory } from 'vue-router'
import index from '../views/index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    redirect: '/home',
    children: [
      // 首页
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/homePage/home.vue')
      },
      // 智慧平台
      {
        path: '/smartPlatform',
        name: 'smartPlatform',
        component: () => import('../views/smartPlatform/index.vue')
      },
      // 产品设备
      {
        path: '/productEquipment',
        name: 'productEquipment',
        component: () => import('../views/productEquipment/index.vue')
      },
      // 解决方案
      {
        path: '/programme',
        name: 'programme',
        component: () => import('../views/programme/index.vue')
      },
      // 客户案例
      {
        path: '/customerCase',
        name: 'customerCase',
        component: () => import('../views/customerCase/index.vue')
      },
      // 新闻中心
      {
        path: '/news',
        name: 'index',
        component: () => import('../views/newsCenter/index.vue'),
        redirect: '/news/center',
        children: [
          {
            path: 'center',
            name: 'center',
            meta: {
              isShow: false,
              name: '新闻中心'
            },
            component: () => import('../views/newsCenter/center.vue')
          },
          {
            path: 'newdetails',
            name: 'newdetails',
            meta: {
              isShow: true,
              name: '新闻详情'
            },
            component: () => import('../views/newsCenter/newdetails.vue')
          }
        ]
      },
      // 关于我们
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('../views/aboutUs/index.vue')
      }
    ]
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('../views/login.vue')
  // }
]

// 路由拦截

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

export default router
