<template>
  <footer>
    <div class="footer_main">
      <!-- 网站导航信息板块 -->
      <div class="left">
        <ul v-for="(item, index) in dataList" :key="index">
          <li>
            <div>{{ item.name }}</div>
            <div v-for="(sub, idx) in item.data" :key="idx">
              <router-link v-if="sub.url" :title="sub.name" :to="sub.url">{{ sub.name }}</router-link>
                <div class="text" v-else>{{ sub.name}}：<span> {{ sub.number }}</span></div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 二维码板块 -->
      <div class="right">
        <img class="logo" src="../assets/image/bt_logo.png" alt="" />
        <div class="qrcode_list">
          <div class="qrcode">
            <img src="../assets/image/product_qrcode.png" alt="">
            <p>扫码了解产品</p>
          </div>
          <div class="qrcode">
            <img src="../assets/image/official_account_qrcode.png" alt="">
            <p>扫码关注公众号</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 备案信息 -->
    <div class="record">
      <a target="_blank" href="https://beian.miit.gov.cn" class="icp">
        <span>粤ICP备2022097811号</span>
      </a>
    </div>
  </footer>
</template>
<script setup>
  import { reactive } from 'vue'
  // 网站导航信息
  const dataList = reactive([
    { name: '热门推荐', data: [{ name: '智慧平台', url: '/smartPlatform' }, { name: '产品设备', url: '/productEquipment' }, { name: '解决方案', url: '/programme' }, { name: '客户案例', url: '/customerCase' }] },
    { name: '关于我们', data: [{ name: '公司介绍', url: '/aboutUs' }, { name: '加入我们', url: '/aboutUs' }] },
    { name: '联系我们', data: [{ name: '联系电话', number: '400-118-0084' }, { name: '合作市场', number: 'm15875303700@163.com' }] }
  ])

</script>
<style lang="scss" scoped>
footer {
    width: 100%;
    height: 360px;
    color: #fff;
    background-image: url('../assets/image/footer_bg.png');
    background-position: center;
    background-size: 1920px auto;
    background-repeat: no-repeat;
    background-color: #1b2330;
    .footer_main {
      max-width: 1920px;
      width: 1206px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 320px;
      padding-top: 60px;
      box-sizing: border-box;
      .left {
        display: flex;
        ul {
          margin-right: 60px;
          list-style: none;
          cursor: default;
          li {
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            div {
              font-size: 14px;
              margin-bottom: 10px;
            }
            a {
              font-size: 12px;
              display: block;
              margin: 10px 0;
              color: rgba(180, 186, 204, 1);
              cursor: pointer;
            }
            a:link {
              text-decoration: none;
            }
            a:active, a:hover {
              text-decoration: underline;
              cursor: pointer;
              color: #fff;
            }
            .text {
              font-size: 12px;
              color: rgba(180, 186, 204, 1);
              span {
                color: #fff;
                font-size: 14px;
              }
            }
          }
        }
      }
      .right {
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        .logo {
          margin-bottom: 40px;
        }
        .qrcode_list {
          display: flex;
        }
        .qrcode:first-of-type {
          margin-right: 40px;
        }
        p {
          font-size: 12px;
          text-align: center;
          margin-top: 8px;
        }
      }
    }
    .record {
      width: 100%;
      height: 40px;
      background-color: rgba(0, 0, 0, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      a {
        color: rgba(255,255,255,0.8);
        text-decoration: none;
        letter-spacing: 1px;
      }
    }
  }
</style>
