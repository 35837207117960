import axios from 'axios'
// import store from '@/store'
import { ElMessage } from 'element-plus'
import router from '@/router'

// axios实列对象
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true,
  headers:{
    'Content-Type': 'application/json;charset=UTF-8',
  },
  timeout: 3000
})
// 请求拦截器 request
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (!token) {  
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    } else {
      config.headers['Authorization'] = "Bearer " + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 响应拦截器 response
service.interceptors.response.use(
  response => {
    // 这里拦截401错误，并重新跳入登页重新获取token
    // 未设置状态码则默认成功状态
    const code = response.data.code || 200;
    const error_500 = response.data.msg || '服务器异常，请联系管理员！'
    if (response.status && response.status === 200) {
      // 通讯成功
      if (code === 200) {
        return response.data
      } else if (code === 401 || code === 403) {
        // 如果是token过期，跳转至登录
        ElMessage.error("登录已过期，请重新登录！");
        // store.commit('userLogout') // 移除token，跳转至登录
        router.replace({
          path: '/'
        })
      }
      return Promise.resolve(response.data)
    }
    if (response.status === 500) {
      ElMessage.error(error_500)
    }
  },
  error => {
    ElMessage.error(error.message)
    return Promise.reject(error)
  }
)

export default service
