<template>
  <router-view/>
</template>

<style>
@import './assets/font/font.css';
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
</style>
