import request from './request'

// 通用 get 接口
function do_get (url) {
  return request({
    url: url,
    method: 'get',
    params: query
  })
}

function _get (urls) {
  return request({
    method: 'GET',
    url: urls,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' }
  })
}

// 通用 get 接口
function do_gett (url, data) {
  return request({
    url: url,
    method: 'get',
    params: data
  })
}

// 通用 post 接口
function do_post (url, data) {
  return request({
    url: url,
    method: 'POST',
    data: data
  })
}
// 通用 put 接口
function do_put (url, data) {
  return request({
    url: url,
    method: 'PUT',
    data: data
  })
}

// 通用 下载文件 接口
export function do_download (url, data) {
  let option = { method: 'POST', url: url, data: data, responseType: 'blob',headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
  return request(option)
}


export default {
  do_get,
  do_gett,
  _get,
  do_post,
  do_put,
  do_download
}
